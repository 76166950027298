export const data = {
	content: {
		projects: [
			{
				id: 1,
				name: "Summary Generation Platform",
				tools: ["React.js", "Node.js", "JavaScript","Azure","REST","Nginx"],
				link: "https://github.com/rohansriram/Azure-Form-Recognizer-API-Integration",
				description:
					"Customized REST APIs that integrate with Form Recognizer Azure API using Node.js from documents decreasing the end-user time to extract key/value pairs by 80%. Endpoints tested on Postman and Swagger for API documentation.       ",
			},
			{
				id: 2,
				name: "Music MeetUps",
				tools: [
					"HTML5",
					"CSS",
					"MongoDB",
					"Node.js",
					"Express.js",
					"MVC"
				],
				link: "https://github.com/rohansriram/Music-Meetups",
				description:
					"Developed a web application based on the MVC architecture using Node.js, MongoDB, Express.js framework to provide a platform that can accommodate huge traffic, data flow, synchronous transactions where users can register, find meetup events with a common interest. Engineered the common interest platform detection algorithm by using data analytics, python, and integrated the system with database and seamless UI for better user performance and increasing audience for the product.",
			},
			{
				id: 3,
				name: "Trip Planner Application",
				tools: [
					"Java",
					"Android Studio",
					"Google Analytics",
					"Firebase",
					"Google Maps",
					"Sessions",
					"JSON Web Tokens"
					
				],
				link: "https://github.com/rohansriram/TripPlanner",
				description: "Led a team of 3 to build an innovative application using java where google authentication is used to manage users, perform CRUD operations for trips by adding a destination and places nearby. Each trip has a chatroom where users send/delete messages and request to join a trip. The final trip is integrated with Google maps.",
			},
			{
				id: 4,
				name: "Plane Detection using Augmented Reality",
				tools: ["React.js", "Hooks", "Node.js", "Python", "Agile", "Augmented Reality"],
				link:
					"https://github.com/Ayappa/PrescriptionHolder_FrontEnd_ReactNative",
				description: "Collaborated with Industry partner Avo Insights to detect an object on a plane surface and render it to the browser on the front-end using React.js and the back-end Node.js thereby increasing scalability and clients by 5%. ",
			},
			{
				id: 5,
				name: "Mango Classification using Convolutional Neural Networks ",
				tools: ["Convolutional Neural Networks", "Python", "TensorFlow"],
				link: "https://www.irjet.net/archives/V5/i11/IRJET-V5I11326.pdf",
				description:
					"Classified 5 different classes of mangoes in real-time using convolutional neural networks under the TensorFlow environment using Python. Find the paper publication  below:",
			},
			{
				id: 6,
				name: "Email Application",
				tools: [
					"Android",
					"Java",
					"HTTPConnections",
					"API",
					"Json web token",
					"Sessions",
				],
				link:
					"https://github.com/rohansriram/Email_Application",
				description:
					"Developed a typical MailBox using HTTP connections to create and authenticate users. To send, receive and delete emails amongst themselves",
			},
			// {
			// 	id: 7,
			// 	name: "Beacon Android-Shopping-app with-Stripe-Payment",
			// 	tools: [
			// 		"Android",
			// 		"Java",
			// 		"Node",
			// 		"API",
			// 		"MongoDb",
			// 		"Json web token",
			// 		"Sessions",
			// 		"Bluetooth Manager",
			// 		"Beacons",
			// 		"Stripe Payment",
			// 	],
			// 	link:
			// 		"https://github.com/Ayappa/Beacon-Android-Shopping-app-with-Stripe-Payment",
			// 	description:
			// 		"Authenticate and Authorize users in Android Application to shop items with inbuild payment gateway ,handling payment token middleware in node.With Beacons , to get particular products as you near particular beacons. With shooping cart for authenticated and authorized users.",
			// },
			// {
			// 	id: 8,
			// 	name: "AirBnb Look alink hybrid App",
			// 	tools: [
			// 		"Ionic4",
			// 		"Angular",
			// 		"MongoDb",
			// 		"API",
			// 		"HTML",
			// 		"CSS",
			// 		"JavaScript",
			// 		"Json web token",
			// 		"Sessions",
			// 	],
			// 	link: "https://github.com/Ayappa/AirBnb-Like-using-Angular-and-ionic4",
			// 	description:
			// 		"Authenticate and Authorize users to book , add or explore places. With basic Crud operation for places. Node API as backend.",
			// },
			// {
			// 	id: 9,
			// 	name: "Beacon Android-Shopping-app with-BrainTree-Payment",
			// 	tools: [
			// 		"Android",
			// 		"Java",
			// 		"Node",
			// 		"API",
			// 		"MongoDb",
			// 		"Json web token",
			// 		"Sessions",
			// 		"Bluetooth Manager",
			// 		"Beacons",
			// 		"BrainTree Payment",
			// 	],
			// 	link: "https://github.com/Ayappa/Shooping-App-Android-and-express",
			// 	description:
			// 		"Authenticate and Authorize users in Android Application to shop items with inbuild payment gateway ,handling payment token middleware in node.With Beacons , to get particular products as you near particular beacons. With shooping cart for authenticated and authorized users.",
			// },
			// {
			// 	id: 10,
			// 	name: "ConnectionApp--Node.js",
			// 	tools: [
			// 		"Node.js",
			// 		"HTML",
			// 		"CSS",
			// 		"API",
			// 		"MongoDb",
			// 		"Json web token",
			// 		"Sessions",
			// 	],
			// 	link: "https://github.com/Ayappa/ConnectionApp--Node-js-Express-API",
			// 	description:
			// 		"Authenticate and Authorize users to create event , view or explore events around and let them know if your intrested in going , edit created events.With Node as backend.",
			// },
			// {
			// 	id: 11,
			// 	name: "Contact-Keeper FrontEnd",
			// 	tools: ["React", "Node.js", "HTML", "CSS", "JavaScript"],
			// 	link:
			// 		"https://github.com/Ayappa/Contact-Keeper--MERN-stack-app-using-React/tree/master/middleware",
			// 	description:
			// 		"Authenticate and Authorize users to add , edit or lookup contacts . With Node as backend.",
			// },
			// {
			// 	id: 12,
			// 	name: "Contact-Keeper BackEnd",
			// 	tools: ["Node.js", "API", "MongoDb", "Json web token", "Sessions"],
			// 	link: "https://github.com/Ayappa/ContactKeeper-API-NODE-js-EXPRESS",
			// 	description:
			// 		"Authenticate and Authorize users to add , edit or lookup contacts . With Node as backend.",
			// },
			// {
			// 	id: 13,
			// 	name: "RecipeHandBook-ionic",
			// 	tools: ["Ionic4", "Angular", "HTML", "CSS", "JavaScript"],
			// 	link: "https://github.com/Ayappa/RecipeHandBook-ionic",
			// 	description: "Add, edit and delete recipies  on local storage ",
			// },
			// {
			// 	id: 14,
			// 	name: "GitFinder",
			// 	tools: ["React", "Hooks", "HTML", "CSS", "JavaScript"],
			// 	link: "https://github.com/Ayappa/GitFinder-ReactApp",
			// 	description:
			// 		"Search a gitProfile and view details of user and visit his git page, with Git API",
			// },
			// {
			// 	id: 15,
			// 	name: "Bluetooth Low Energy Android App",
			// 	tools: ["Android", "Java", "BlueTooth"],
			// 	link:
			// 		"https://github.com/Ayappa/BLE-android/blob/master/app/src/main/java/com/example/ble/MainActivity.java",
			// 	description:
			// 		"Conneect BLE devices and control the device on your app (e.g blub)",
			// },
			// {
			// 	id: 16,
			// 	name: "Hadoop-MapReduce -java",
			// 	tools: ["Java", "Eclipse", "Hadoop", "Map reduce"],
			// 	link: "https://github.com/Ayappa/Hadoop-MapReduce-in-java",
			// 	description:
			// 		"Read a huge data of file, clean the input and perform map-reduce to count word frequency",
			// },
			// {
			// 	id: 17,
			// 	name: "Emotion-Detection-with-OCR--Machine-Learning-and-Neural-Network",
			// 	tools: [
			// 		"Python",
			// 		"Anaconda",
			// 		"Machine Learning",
			// 		"Neural Network",
			// 		"Text Processing",
			// 		"OCR",
			// 		"Feature Extraction",
			// 	],
			// 	link:
			// 		"https://github.com/Ayappa/Emotion-Detection-with-OCR--Machine-Learning-and-Neural-Network",
			// 	description:
			// 		"React the input from image or text , and extract the text and features and predict against dataSet(emotion texts) which is cleaned(Tokenization ,Lemmatizing) in to feature set ,to predict 12 classes of emotion ",
			// },
			// {
			// 	id: 18,
			// 	name: "Android-16-small-scale-apps",
			// 	tools: ["Android", "Java"],
			// 	link: "https://github.com/Ayappa/Android-16-small-scale-apps",
			// 	description:
			// 		"Small scare app for course work , which in clude Api calls and basic android concepts for understanding",
			// },
			// {
			// 	id: 19,
			// 	name: "ChartApp-with-Uber-like-integration-Android",
			// 	tools: [
			// 		"Android",
			// 		"Java",
			// 		"Sessions",
			// 		"API",
			// 		"Google Maps",
			// 		"Google Places",
			// 		"Location Manager",
			// 		"FireStore",
			// 		"Json Web Token",
			// 	],
			// 	link:
			// 		"https://github.com/Ayappa/ChartApp-with-Uber-like-integration-Android",
			// 	description:
			// 		"Authenticate and Authorize users , to create chat rooms and explore chatRooms, allow users request users a ride and allow user to select a ride from array of rides who have accepted the ride request in a particular chat room. After accepting ride allow tracking for driver and rider and finish the ride after destination arived. With node as back end for viewing the history of rides.One user can ride or drive at a given time. ",
			// },
			// {
			// 	id: 20,
			// 	name: "ChatBox",
			// 	tools: [
			// 		"Android",
			// 		"Java",
			// 		"Sessions",
			// 		"API",
			// 		"FireStore",
			// 		"Json Web Token",
			// 	],
			// 	link: "https://github.com/Ayappa/ChatBox",
			// 	description:
			// 		"Authenticate and Authorize users , to create chat rooms and explore chatRooms.",
			// },
			// {
			// 	id: 21,
			// 	name: "IT-Logger",
			// 	tools: ["React", "Hooks", "Redux", "HTML", "CSS", "JavaScript"],

			// 	link: "https://github.com/Ayappa/IT-Logger--using-REACT-REDUX",
			// 	description:
			// 		"Search , create or explore logs and store on local storage.",
			// },
			// {
			// 	id: 22,
			// 	name: "BudgetApp",
			// 	tools: ["Ionic4", "Angular", "HTML", "CSS", "JavaScript"],
			// 	link: "https://github.com/Ayappa/BudgetApp-ionic4",
			// 	description: "Add , edit or delete expenses on local storage",
			// },
		],
	},
};
